import React from 'react'
import Product from '../../../models/Product';

export const ReturnProduct: React.FC<{product: Product, path: string}> = (props) => {
 let image = props.path +props.product.img;

    return (
 
        <div className='col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-3'>
            <div className='text-center'>
                {props.product.img ?
                    <img
                     src={require('./../../../Images/'+ image)} 
                        width='151'
                        height='233'
                        alt="tshirt"
                    />
                    :
                    <img
                        src={require('./../../../Images/shirts/shirt-1.png')}
                        width='151'
                        height='233'
                        alt="tshirt"
                    />
                }

                <h6 className='mt-2'>{props.product.description}</h6>
                <p>{props.product.category}</p>
                <a className='btn main-color text-white' href='#'>Reserve</a>
            </div>
        </div>
    );
}