import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <div className='main-color'>
            <footer className='container d-flex flex-wrap 
                justify-content-between align-items-center py-5 main-color'>
                <p className='col-md-4 mb-0 text-white'>© Custom Designs</p>
                <ul className='nav navbar-dark col-md-8 justify-content-end'>
                    <li className='nav-item'>
                        <Link to='/home' className='nav-link px-2 text-white'>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/about' className='nav-link px-2 text-white'>
                            About Us
                        </Link>
                    </li>
                    {/*   <li className='nav-item'>
                        <a href='#' className='nav-link px-2 text-white'>
                            Terms and conditions
                        </a>
    </li> */}
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-link px-2 text-white'>
                            Contact Us
                        </Link>
                    </li>
                </ul>

            </footer>
        </div>
    );
}