import Product from "../../../models/Product";
import { Carousel } from "./Carousel";

export const Caps = () => {
  const prod: Product[] = [
    new Product(1, 'Caps', 1,'Caps', 'cap-1.png'),
    new Product(2, 'Caps', 1,'Caps', 'cap-2.png'),
    new Product(3, 'Caps', 1,'Caps', 'cap-3.png'),
    new Product(4, 'Caps', 1,'Caps', 'cap-4.png'),
    new Product(5, 'Caps', 1,'Caps', 'cap-5.png'),
    new Product(6, 'Caps', 1,'Caps', 'cap-6.png'),
    new Product(7, 'Caps', 1,'Caps', 'cap-7.png'),
    new Product(8, 'Caps', 1,'Caps', 'cap-8.png'),
    new Product(9, 'Caps', 1,'Caps', 'cap-1.png')
    ]
    
    return (
      <>
          <Carousel products={prod} path={'caps/'} category={"Caps"} />
      </>
  );
}