class Product {
    id: number;
    description: string;
    available: number;
    category: string;
    img: string;

    constructor(id: number, description: string, available: number, category: string, img: string) {
        this.id = id;
        this.description = description;
        this.available = available;
        this.category = category;
        this.img = img;
    }
}

export default Product;