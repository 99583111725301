
import './App.css';
import { Navbar } from './layouts/NavBarAndFooter/Navbar';
import { Footer } from './layouts/NavBarAndFooter/Footer';
import { HomePage } from './layouts/HomePage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AboutUs } from './layouts/HomePage/components/AboutUs';
import { ContactUs } from './layouts/HomePage/components/ContactUs';
import { TShirts } from './layouts/HomePage/components/TShirts';
import { Caps } from './layouts/HomePage/components/Caps';
import { Hoodies } from './layouts/HomePage/components/Hoodies';
import { Mugs } from './layouts/HomePage/components/Mugs';



export const App = () => {
  return (
    <div className='d-flex flex-column min-vh-100'>

      <Navbar />
      <div className='flex-grow-1'>
        <Routes>
          <Route path='/' element={<Navigate replace to="/home" />}>
          </Route>
          <Route path='/home' element={<HomePage />}>
          </Route>
          <Route path='/about' element={<AboutUs />}>
          </Route>
         <Route path='/contact' element={<ContactUs />}>
          </Route>
          
          <Route path='/tshirts' element={<TShirts />}>
          </Route>
          <Route path='/hoodies' element={<Hoodies />}>
          </Route>
          <Route path='/caps' element={<Caps/>}>
          </Route>
          <Route path='/mugs' element={<Mugs />}>
          </Route>
        </Routes>
      </div>
      <Footer />

    </div>
  );
}