import Product from "../../../models/Product";
import { Carousel } from "./Carousel";

export const TShirts = () => {
  const prod: Product[] = [
  new Product(1, 'Polyester TShirt', 1,'T-Shirt', 'shirt-1.png'),
  new Product(2, 'Polyester TShirt', 1,'T-Shirt', 'shirt-2.png'),
  new Product(3, 'Polyester TShirt', 1,'T-Shirt', 'shirt-3.png'),
  new Product(4, 'Polyester TShirt', 1,'T-Shirt', 'shirt-4.png'),
  new Product(5, 'Polyester TShirt', 1,'T-Shirt', 'shirt-5.png'),
  new Product(6, 'Polyester TShirt', 1,'T-Shirt', 'shirt-6.png'),
  new Product(7, 'Polyester TShirt', 1,'T-Shirt', 'shirt-7.png'),
  new Product(8, 'Polyester TShirt', 1,'T-Shirt', 'shirt-8.png'),
  new Product(9, 'Polyester TShirt', 1,'T-Shirt', 'shirt-9.png')
  ]
  
  return (
    <>
        <Carousel products={prod} path={'shirts/'} category={"T-Shirt"} />
    </>
);
}