import Product from "../../../models/Product";
import { Carousel } from "./Carousel";

export const Mugs = () => {
  const prod: Product[] = [
    new Product(1, 'Ceramic Mug', 1,'Mug', 'mug-1.png'),
    new Product(2, 'Ceramic Mug', 1,'Mug', 'mug-2.png'),
    new Product(3, 'Ceramic Mug', 1,'Mug', 'mug-3.png'),
    new Product(4, 'Ceramic Mug', 1,'Mug', 'mug-4.png'),
    new Product(5, 'Ceramic Mug', 1,'Mug', 'mug-5.png'),
    new Product(6, 'Ceramic Mug', 1,'Mug', 'mug-6.png'),
    new Product(7, 'Ceramic Mug', 1,'Mug', 'mug-7.png'),
    new Product(8, 'Ceramic Mug', 1,'Mug', 'mug-8.png'),
    new Product(9, 'Ceramic Mug', 1,'Mug', 'mug-9.png'),
  ]
  return (
    <>
        <Carousel products={prod} path={'mugs/'} category={"Mugs"} />
    </>
);
}