import Product from "../models/Product";
import { Carousel } from "./HomePage/components/Carousel";
import { ExploreTopProducts } from "./HomePage/components/ExploreTopProducts";

export const HomePage = () => {
    const prod: Product[] = [
        new Product(1, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-1.png'),
        new Product(2, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-2.png'),
        new Product(3, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-3.png'),
        new Product(4, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-4.png'),
        new Product(5, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-5.png'),
        new Product(6, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-6.png'),
        new Product(7, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-7.png'),
        new Product(8, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-8.png'),
        new Product(9, 'Polyester TShirt', 1, 'T-Shirt', 'shirt-9.png')

    ]
    const hoodie: Product[] = [
        new Product(1, 'Hoodie', 1, 'Hoodie', 'hoodie-1.png'),
        new Product(2, 'Hoodie', 1, 'Hoodie', 'hoodie-2.png'),
        new Product(3, 'Hoodie', 1, 'Hoodie', 'hoodie-3.png'),
        new Product(4, 'Hoodie', 1, 'Hoodie', 'hoodie-4.png'),
        new Product(5, 'Hoodie', 1, 'Hoodie', 'hoodie-5.png'),
        new Product(6, 'Hoodie', 1, 'Hoodie', 'hoodie-6.png'),
        new Product(7, 'Hoodie', 1, 'Hoodie', 'hoodie-7.png'),
        new Product(8, 'Hoodie', 1, 'Hoodie', 'hoodie-8.png'),
        new Product(9, 'Hoodie', 1, 'Hoodie', 'hoodie-9.png')
    ]

    const mug: Product[] = [
        new Product(1, 'Ceramic Mug', 1, 'Mug', 'mug-1.png'),
        new Product(2, 'Ceramic Mug', 1, 'Mug', 'mug-2.png'),
        new Product(3, 'Ceramic Mug', 1, 'Mug', 'mug-3.png'),
        new Product(4, 'Ceramic Mug', 1, 'Mug', 'mug-4.png'),
        new Product(5, 'Ceramic Mug', 1, 'Mug', 'mug-5.png'),
        new Product(6, 'Ceramic Mug', 1, 'Mug', 'mug-6.png'),
        new Product(7, 'Ceramic Mug', 1, 'Mug', 'mug-7.png'),
        new Product(8, 'Ceramic Mug', 1, 'Mug', 'mug-8.png'),
        new Product(9, 'Ceramic Mug', 1, 'Mug', 'mug-9.png'),
    ]
    return (
        <>
            <ExploreTopProducts />
            <Carousel products={prod} path={'shirts/'} category={"T-Shirt"} />
            <Carousel products={hoodie} path={'hoodies/'} category={"Hoodie"} />
            <Carousel products={mug} path={'mugs/'} category={"Mugs"} />
        </>
    );



}