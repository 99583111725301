
export const AboutUs = () => {
  return (
    <div className="bg-light py-5">
      <div className="container py-5">
        <div className="row mb-4">
          <div className="col-lg-5">
            <h2 className="display-4 font-weight-light">About Us</h2>
            <p className="font-italic">Welcome to  Custom Designs.
              We are a family run business with a passion for creating custom personlised designs.
              Our services include printing on a wide range of products, including t-shirts, hoodies,
              caps, mugs cell phone casess and more.
            </p>

            <p className="font-italic"> We take pride in the quality of materials we source and also the quality of inks and finished products.
            </p>
            <p className="font-italic"> 
            Our journey began in April 2024 as a hobby and we are in the process of growing it into a business.
            At the core of our business are our values and principles which are centered around bringing the best quality products
            at reasonable prices to our valued customers. </p>


          </div>
        </div>

      </div>
    </div>



  );
}