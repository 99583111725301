import Product from "../../../models/Product";
import { Carousel } from "./Carousel";

export const Hoodies = () => {

  
  const prod: Product[] = [
    new Product(1, 'Hoodie', 1,'Hoodie', 'hoodie-1.png'),
    new Product(2, 'Hoodie', 1,'Hoodie', 'hoodie-2.png'),
    new Product(3, 'Hoodie', 1,'Hoodie', 'hoodie-3.png'),
    new Product(4, 'Hoodie', 1,'Hoodie', 'hoodie-4.png'),
    new Product(5, 'Hoodie', 1,'Hoodie', 'hoodie-5.png'),
    new Product(6, 'Hoodie', 1,'Hoodie', 'hoodie-6.png'),
    new Product(7, 'Hoodie', 1,'Hoodie', 'hoodie-7.png'),
    new Product(8, 'Hoodie', 1,'Hoodie', 'hoodie-8.png'),
    new Product(9, 'Hoodie', 1,'Hoodie', 'hoodie-9.png')
    ]
    
    return (
      <>
          <Carousel products={prod} path={'hoodies/'} category={"Hoodie"} />
      </>
  );
}